import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { IHomeFormInput } from "../../interfaces/IHomeFormInput";
import { default_welcome } from "../../utils/consts";

export interface WelcomeSectionProps {
  disabled: boolean;
}

export function WelcomeSection(props: WelcomeSectionProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext<IHomeFormInput>();
  const { disabled } = props;
  return (
    <Controller
      name="welcome_message"
      control={control}
      render={({ field }) => {
        const value = field.value || default_welcome()
        return (
          <TextField
            {...field}
            value={value}
            disabled={disabled}
            error={errors.welcome_message !== undefined}
            helperText={
              errors.welcome_message ? errors.welcome_message?.message : "　"
            }
            sx={{
              maxWidth: 530,
              width: "100%",
            }}
            className="rounded-md w-full !ring-transparent !outline-none focus:outline-none bg-white/10 focus:border-white/80 transition-all focus:bg-white/5"
            type="text"
            inputProps={{
              maxLength: 500,
              style: {
                padding: "10px 12px",
              },
            }}
          />
        )
      }}
    />
  );
}
