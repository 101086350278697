import { Crisp } from 'crisp-sdk-web';
import React, {
  ReactElement,
  useContext,
  useEffect,
} from 'react';
import AuthContext from '../../context/AuthContext';
export default function CrispChat(): ReactElement {
  const {
    data: {
      account
    }
  } = useContext(AuthContext);

  const loadCrisp = async () => {
    const crispId: string | undefined = process.env.REACT_APP_CRISP_WEBSITE_ID;
    if (crispId) {
      Crisp.configure(crispId);
    }
  };

  useEffect(() => {
    loadCrisp();
  }, []);

  const account_id = account?.id
  const email = account?.username
  useEffect(() => {
    Crisp.user.setEmail(email || '');
    Crisp.session.setData({
      user_id: account_id,
    });
  }, [account_id, email]);

  return <></>;
}
