import {
  Box,
  Grid,
  Link,
  Stack,
  Typography
} from "@mui/material";

import LandingViewForm from "../components/LandingViewForm";
import { NucleusButton } from "../components/NucleusButton";
import { useEffect } from "react";

function debounce<T extends (...args: any[]) => void> (fn: any, wait: number, immediate: boolean) {
  let timeout:string|number|NodeJS.Timeout|undefined;
  return function <U>(this: U, ...args: Parameters<typeof fn>) {
      const context = this; 
      const later = function () {
          timeout = undefined;
          if (!immediate) fn.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) fn.apply(context, args);
  };
}

function isInView (el: Element) {
  const rect = el.getBoundingClientRect();
  const elemTop = rect.top;
  // Only completely visible elements return true:
  //let isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
  let isVisible = elemTop <= window.innerHeight;
  // if (direction === 'up') {
  if (rect.height > window.innerHeight && elemTop <= window.innerHeight) { isVisible = true; }
  // Partially visible elements return true:
  // isVisible = elemTop < window.innerHeight && elemBottom >= 0;
  return isVisible;
}

//check to see if any animation containers are currently in view
const checkInView = debounce(function (animation_elements:Element[]) {
    //iterate through elements to see if its in view
    for (let i=0; i<animation_elements.length; i++) {
        const element = animation_elements[i];
        if (!element.classList.contains('in-view')) {
          if (isInView(element)) {
            element.classList.add('in-view');
          }
        }
    }
}, 150, false);


export default function LandingView() {
  useEffect(() => {
    const animation_elements = document.querySelectorAll('.animated:not(.in-view)');
    checkInView(animation_elements);
    window.addEventListener('scroll', (e => checkInView(animation_elements)));
    
    //Deeplinking not working on chrome
    setTimeout(function () {
      var hash = window.location.hash;
      if(hash) {
        window.location.hash = "";
        window.location.hash = hash;
      }
    }, 300);

  }, []);

  return (
    <Box sx={{ margin: "auto" }}>
      <Grid container margin={0} direction="column">
      <Stack component="section" direction="column" className="dark video" padding={2}>
          <video width="auto" height="auto" autoPlay={true} loop={true} muted={true} playsInline={true}>
            <source src={require('../assets/videos/nucleus-background-short.mp4')} type="video/mp4"/>
          </video>
          <Box className="wrapper">
            <Box className="block-left">
              <Typography variant="h1">The Cloud Based AI Phone System</Typography>
              <Typography variant="h2">The Voice at the Center of your Business.</Typography>
              <Typography variant="body1">Nucleus is the first free AI cloud based phone system that comes fully equipped with your own business phone number and Alice - your very own AI attendant that you can activate in 30 seconds. No app required.</Typography>
              <Box className="buttons">
                <Link href="#activatealice">
                  <NucleusButton variant="contained" className="pulse">Activate Alice</NucleusButton>
                </Link>
              </Box>
            </Box>            
          </Box>    
		  </Stack>         
      <Stack component="section" direction="column" padding={2}>
          <Box className="text" >
            <Typography variant="h1">Your AI Attendant</Typography>
            <Typography variant="h2">Hi! I'm Alice.</Typography>
            <Typography variant="body1">
            I'm the voice at the center of your business. Right now I can be your receptionist - I can answer your call, transfer your call, and if you're not available, 
            I can email or text you the message from your caller. In the future, I will be able to do much more - like scheduling, booking appointments, and reminder calls to customers.
            My founders will soon bring in Diego, Bob, Maria, and others who can also do these tasks, and we can work together for you as a team. 
            </Typography>
            <Typography variant="body1">
            I can start work right away! There's no app required, and I'm free!
            </Typography>
            <Box className="buttons">
              <Link href="#activatealice">
                <NucleusButton variant="contained" className="pulse">Activate Alice</NucleusButton>
              </Link>
            </Box>
          </Box>            
		  </Stack>
      <Stack component="section" className="dark video full">
          <video width="auto" height="auto" autoPlay={false} loop={true} controls={true}  muted={false}>
            <source src={require('../assets/videos/alice-intro.mp4')} type="video/mp4"/>
          </video>
      </Stack>
      <Stack className="white padding">
      <Stack component="section" className="left">
        <Box className="row" >
          <article className="box-stretch">
            <h1>AI Receptionist</h1>
            <h2>Free your hands</h2>
            <div className="text">			
              <p>As a solo entrepreneur in the trades, your hands are always full – literally! You juggle tools, materials, and customer expectations. Let Alice take some of the weight by handling your calls and forwarding your messages without interrupting your workflow.</p>
            </div>
          </article>
          <article className="box-stretch">
            <img src="/images/use-case-handyman.png" className="full" alt="" />	
          </article>
		    </Box>	
      </Stack>	
      <Stack component="section" className="right">
        <Box className="row" >
          <article className="box-stretch">
            <img src="/images/use-case-small-team.png" className="full" alt="" />	
          </article>
          <article className="box-stretch">
            <div>
              <h1>AI Attendant</h1>
              <h2>Empower Your Team</h2>
              <div className="text">			
                <p>Focus on tasks requiring a human touch, and trust Alice to handle FAQs, provide product or service info, and offer basic quotes. Set your office hours for call routing, and customize Alice with specific greetings and responses to ensure communications reflect your brand and standards. Get started without any technical hassle – because your team has enough to do!</p>
              </div>
            </div>
          </article>    
		    </Box>	
      </Stack>              
      <Stack component="section" className="left">
        <Box className="row" >
          <article className="box-stretch">
              <h1>Custom Phone Numbers</h1>
              <h2>Rethink Marketing</h2>
              <div className="text">			
                <p>Launch Nucleus and quickly add phone numbers to embed in ads and landing pages. Train your Nucleus Attendant to promote and answer questions about a product, service, or upcoming event, and ensure that every customer interaction aligns with your brand voice and messaging. Experiment with different scripts or offers across multiple phone numbers to optimize results. Respond swiftly to market changes or customer concerns with tailored messaging that provides immediate, personalized responses.</p>
              </div>
          </article>
          <article className="box-stretch">
            <img src="/images/use-case-marketing.png" className="full" alt="" />	
          </article>
        </Box>
      </Stack>	


      </Stack>
      <Stack component="section" className="blue">
        <Box className="animated left row" >
        <article>
				<h1>What I can do now for free</h1>
				<div className="list">				
					<ul className="features">
						<li>Provide a new US or Canadian phone number for your business</li>
						<li>Add up to 10 team members to your company directory</li>
						<li>Transfer calls to any team member in the US or Canada*</li>
            <li>Interpret nuances in your callers' requests</li>
						<li>Send messages via text or email outside of office hours</li>
					</ul>
				</div>
			  </article>
        <article>
				<h1>What I can also do for Premium users</h1>
				<div className="list">				
          <ul className="features">
						<li>Communicate a custom greeting and end call message</li>
						<li>Answer questions based on your business using knowledge cards</li>
						<li>Transfer your existing business number</li>
            <li>Provide more advanced voice models for more natural responses</li>
					</ul>
				</div>
			  </article>
        </Box>
      </Stack>

      <Stack component="section" direction="column" padding={2} id="activatealice">
        <Box className="text" >
          <Typography variant="h1">Activate Alice</Typography>
          <Typography variant="h2">Configure Alice in 30 seconds.</Typography>
          <Typography variant="body1">
            Receive a dedicated business phone number and your Al attendant, Alice. <strong>No credit card or app download required.</strong> Activate your  <strong>free Starter</strong> account below
          </Typography>
          <Box className="text" >
          <LandingViewForm /> 
          </Box>
        </Box>
      </Stack>

      <Stack component="section" className="blue" >
			<Box className="animated right row">
				<article>
					<img src="/images/salon-owner.jpg" alt=""/>
					<Box className="text">
					<h1>Our Mission</h1>
          <h2>Empowering entrepreneurs and small businesses</h2>
			    <p>We have a big goal. Within the next year, we want to empower one million entrepreneurs and small business owners just like you. 
			Join us in our movement of empowerment to make AI accessible for the good of all. Activate Alice and start saving on your business phone system. 
			Let everyone else know, so that every entrepreneur and small business can start saving.</p>
					</Box>
				</article>
				<article>
					<img src="/images/our-team.png" alt="" />
					<Box className="text">
			<h1>Our Team</h1>
			<h2>Nucleus Technologies Inc</h2>
			<p>At Nucleus Technologies we empower the small stakeholder by simplifying AI integration, making it accessible and valuable for all.  </p>
 			<p>We have leveraged advanced cloud-based phone system technologies and proprietary AI language models to create a simple yet powerful solution. In 30 seconds, you can create a customized phone system that acts like an employee, instantly enhancing productivity and customer service quality. </p> 
					</Box>
				</article>	
      </Box>
		  </Stack>
      </Grid>
    </Box>
  );
}
