import {
  Alert,
  Box,
  Button,
  Dialog,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState, useContext, useEffect } from "react";
import AuthContext from "../../context/AuthContext";
import * as yup from "yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMediaMobile, useReferer } from "../../utils/utils";
import ApiContext from "../../context/ApiContext";
import { DeleteAccountDialog } from "./DeleteAccountDialog";
import { useNavigate } from "react-router-dom";
import { AccessLevel } from "../../interfaces/IGetUserResult";
import { ErrorAlert } from "../alert/ErrorAlert";

interface IFormInput {
  email: string;
}

const schema = yup
  .object({
    email: yup
      .string()
      .required("Email field is required.")
      .max(50)
      .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/, {
        message: "Please enter a valid email address.",
        excludeEmptyString: true,
      }),
  })
  .required();

export interface ProfileDialogProps {
  open: boolean;
  onClose: (reason?: string) => void;
}

export function ProfileDialog(props: ProfileDialogProps) {
  const navigate = useNavigate();
  const referer = useReferer();
  const theme = useTheme();
  const isMobile = useMediaMobile();

  const {
    data: { account },
  } = useContext(AuthContext);
  const {
    actions: { forgotPassword, updateAccount },
  } = useContext(AuthContext);

  const email = account?.username

  const { open, onClose } = props;
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onSubmit",
    defaultValues: {
      email: email || "",
    },
  });

  useEffect(() => {
    if(email)
      setValue("email", email);
  }, [open, email, setValue]);

  const onSubmit: SubmitHandler<IFormInput> = (data: IFormInput) => {
    const { email } = data;
    setError(null);
    setSuccess("");
    setLoading(true);
    updateAccount(email, undefined, referer)
      .then((response) => {
        setError(null);
        reset();
        onClose();
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleResetPassword = async (e: any) => {
    if (email) {
      setError(null);
      setSuccess("");
      setLoading(true);
      forgotPassword(email)
        .then((response) => {
          setError(null);
          setSuccess(`A password reset link was sent to ${email}`);
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleBilling = async (e: any) => {
    e.preventDefault();
    onClose();
    navigate("/billing");
  };

  const [deleteAccountDialogOpen, setDeleteAccountDialogOpen] = useState(false);

  return (
    <Dialog
      open={open}
      onClose={() => {
        reset();
        onClose();
      }}
    >
      <DeleteAccountDialog
        open={deleteAccountDialogOpen}
        onClose={() => setDeleteAccountDialogOpen(false)}
      />
      <Paper
        sx={{
          width: { xs: 320, sm: 350 },
          padding: theme.spacing(2),
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            direction="column"
            spacing={0.75}
            justifyContent="center"
            alignItems="stretch"
          >
            <ErrorAlert error={error} />
            {success && <Alert severity="success">{success} </Alert>}

            <Typography variant="h4">Settings</Typography>
            <Box>
              <Typography
                variant="body1"
                sx={{ marginBottom: isMobile ? 0 : 1 }}
              >
                Email
              </Typography>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={errors.email !== undefined}
                    helperText={errors.email ? errors.email?.message : "　"}
                    className="rounded-md w-full !ring-transparent !outline-none focus:outline-none bg-white/10 focus:border-white/80 transition-all focus:bg-white/5"
                    type="text"
                    inputProps={{
                      style: {
                        padding: "10px 12px",
                      },
                    }}
                  />
                )}
              />
            </Box>
            <Button sx={{ width: "100%" }} variant="contained" type="submit">
              Update Account
            </Button>
            <Button
              sx={{ width: "100%" }}
              variant="outlined"
              onClick={handleResetPassword}
            >
              Reset Password
            </Button>
            {account?.access_level !== AccessLevel.GUEST && (
              <Button
                sx={{ width: "100%" }}
                variant="outlined"
                onClick={handleBilling}
              >
                Billing Portal
              </Button>
            )}
            <Button
              sx={{ width: "100%" }}
              variant="outlined"
              color="error"
              onClick={() => setDeleteAccountDialogOpen(true)}
            >
              Close Account
            </Button>
          </Stack>
        </form>
      </Paper>
    </Dialog>
  );
}
