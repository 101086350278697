import { TeamMember } from "./Agent";
import { CardItem } from "./CardItem";

export enum AccessLevel {
  SUSPENDED = "SUSPENDED",
  GUEST = "GUEST",
  FREE = "FREE",
  PRO = "PRO",
  CLOSED = "CLOSED",
  SUPER = "SUPER",
}

export interface IAccount {
  id: number;
  username: string;
  access_level: AccessLevel;
  has_password: boolean;
  verified: boolean;
}

export interface ICompany {
  id: number;
  name: string;
  did: string;
  voice: string;
  users: TeamMember[];
  cards: CardItem[];
  welcome_message?: string;
  goodbye_message?: string;
  time_zone?: string;
}

export interface IStripe {
  trial_start?: string
  trial_end?: string
}

export interface IGetUserResult {
  account: IAccount;
  company: ICompany;
  stripe?: IStripe;
}

export interface ICreateStripePriceResult{
  price_id: string
  secret: string

}
