import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardProps,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { IHomeFormInput } from "../interfaces/IHomeFormInput";
import { MAX_CARD_LENGTH } from "../utils/consts";

export interface ModelCardProps extends CardProps {
  index: number;
  disabled?: boolean;
  onCardRemove: () => void;
}

export function ModelCard(props: ModelCardProps) {
  const { index, disabled, onCardRemove, ...restProps } = props;
  const theme = useTheme();
  const {
    control,
    formState: { errors },
  } = useFormContext<IHomeFormInput>();

  return (
    <Card
      {...restProps}
      sx={{
        backgroundColor: "#F8FAFE",
        ...props.sx,
      }}
    >
      <CardHeader
        avatar={
          <Avatar
            sx={{ bgcolor: "#7c7d7f", marginBottom: "16px" }}
            aria-label="recipe"
          >
            {index + 1}
          </Avatar>
        }
        title={
          <Controller
            control={control}
            name={`cards.${index}.title`}
            render={({ field }) => {
              let e =
                errors?.cards?.at !== undefined &&
                errors?.cards.at(index) !== undefined &&
                errors?.cards?.at(index)?.title?.message;
              return (
                <TextField
                  {...field}
                  error={e !== undefined && e !== false}
                  helperText={e ? e.toString() : "　"}
                  sx={{
                    "& .MuiOutlinedInput-input": { background: "white" },
                    "& .MuiFormHelperText-root": {
                      margin: "6px 0 0 0 ",
                      "&.Mui-error": {
                        margin: "6px 0 0 0 ",
                      },
                    },
                    width: "100%",
                  }}
                  variant="outlined"
                  type="text"
                  placeholder="Title"
                  inputProps={{
                    maxLength: 50,
                    style: {
                      padding: "10px 12px",
                    },
                  }}
                  disabled={disabled}
                  data-no-dnd="true"
                />
              );
            }}
          />
        }
      />
      <CardContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          spacing={0}
        >
          <Controller
            control={control}
            name={`cards.${index}.body`}
            render={({ field }) => {
              const tooManyChar = field.value.length > MAX_CARD_LENGTH;
              const e =
                errors?.cards?.at !== undefined &&
                errors?.cards.at(index) !== undefined &&
                errors?.cards?.at(index)?.body?.message;
              return (
                <FormControl sx={{ width: "100%" }} error={e !== undefined}>
                  <TextField
                    {...field}
                    error={e !== undefined && e !== false}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        background: "white",
                        padding: 0,
                      },
                    }}
                    placeholder="Enter instructions for the system here"
                    multiline
                    rows={10}
                    disabled={disabled}
                    data-no-dnd="true"
                    inputProps={{
                      style: {
                        padding: "10px 12px",
                      },
                    }}
                  />
                  <FormHelperText
                    sx={{
                      "&.Mui-error": {
                        marginBottom: 0,
                      },
                      fontWeight: "normal",
                    }}
                  >
                    <Stack
                      direction="row-reverse"
                      spacing={2}
                      sx={{
                        justifyContent: "space-between",
                        alignItems: "stretch",
                      }}
                    >

                      <Typography sx={{
                        color: !tooManyChar
                          ? theme.palette.text.primary
                          : theme.palette.error.main,
                        fontSize: "12px",
                        fontWeight: 700
                      }}>  {field.value.length}/{MAX_CARD_LENGTH}</Typography>
                      {e && (<Typography sx={{ color: theme.palette.error.main, fontSize: "12px", fontWeight: 700 }}> {e}</Typography>)}
                    </Stack>
                  </FormHelperText>
                </FormControl>
              );
            }}
          />
        </Stack>
      </CardContent>
      <CardActions sx={{ paddingTop: 0 }}>
        <Button
          size="small"
          onClick={() => onCardRemove()}
          sx={{ marginLeft: "8px" }}
          data-no-dnd="true"
          disabled={disabled}
        >
          Remove
        </Button>
      </CardActions>
    </Card>
  );
}
