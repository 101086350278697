import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useRef } from "react";
import AvailabilityAccordian from "./AvailabilityAccordian";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { IHomeFormInput } from "../interfaces/IHomeFormInput";
import { useMediaMobile } from "../utils/utils";
import { CommunicationType, Status } from "../interfaces/Agent";

interface TeamMemberProps {
  index: number;
  showRemove: boolean;
  expanded: boolean;
  onAgentRemove: () => void;
  onExpandChange: (expanded: boolean, event?: React.SyntheticEvent) => void;
}

export function TeamMember(props: TeamMemberProps) {
  const { index, showRemove, expanded, onAgentRemove, onExpandChange } = props;
  const isMobile = useMediaMobile();
  const accordianRef = useRef<HTMLDivElement | null>();

  const {
    control,
    watch,
    getValues,
    formState: { errors },
  } = useFormContext<IHomeFormInput>();

  const handleRemoveAgent = useCallback(() => {
    onAgentRemove();
  }, [onAgentRemove]);

  const handleExpandChange = (
    expanded: boolean,
    event?: React.SyntheticEvent,
  ) => {
    const status = getValues(`agents.${index}.status`) || Status.AVAILABLE;
    onExpandChange(expanded && status === Status.SCHEDULE, event);
  };

  useEffect(() => {
    const status = getValues(`agents.${index}.status`) || Status.AVAILABLE;
    handleExpandChange(status === Status.SCHEDULE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch(`agents.${index}.status`)]);

  return (
    <Controller
      control={control}
      name={`agents.${index}.status`}
      render={({ field: statusField }) => {
        const statusValue = statusField.value || Status.AVAILABLE;
        return (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={`agents.${index}.first_name`}
                render={({ field }) => {
                  const e =
                    errors?.agents?.at !== undefined &&
                    errors?.agents.at(index) !== undefined &&
                    errors?.agents?.at(index)?.first_name?.message;
                  return (
                    <TextField
                      {...field}
                      error={e !== undefined && e !== false}
                      helperText={e ? e.toString() : "　"}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-input": { background: "white" },
                      }}
                      type="text"
                      placeholder="First Name"
                      inputProps={{
                        className: "bg-white",
                        maxLength: 50,
                        style: {
                          padding: "10px 12px",
                        },
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={`agents.${index}.last_name`}
                render={({ field }) => {
                  const e =
                    errors?.agents?.at !== undefined &&
                    errors?.agents.at(index) !== undefined &&
                    errors?.agents?.at(index)?.last_name?.message;
                  return (
                    <TextField
                      {...field}
                      error={e !== undefined && e !== false}
                      helperText={e ? e.toString() : "　"}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-input": { background: "white" },
                      }}
                      type="text"
                      placeholder="Last Name"
                      inputProps={{
                        maxLength: 50,
                        style: {
                          padding: "10px 12px",
                        },
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={`agents.${index}.phone`}
                render={({ field }) => {
                  const e =
                    errors?.agents?.at !== undefined &&
                    errors?.agents.at(index) !== undefined &&
                    errors?.agents?.at(index)?.phone?.message;
                  return (
                    <TextField
                      {...field}
                      error={e !== undefined && e !== false}
                      helperText={e ? e.toString() : "　"}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-input": { background: "white" },
                      }}
                      type="text"
                      placeholder="Phone"
                      inputProps={{
                        maxLength: 50,
                        style: {
                          padding: "10px 12px",
                        },
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={`agents.${index}.email`}
                render={({ field }) => {
                  const e =
                    errors?.agents?.at !== undefined &&
                    errors?.agents.at(index) !== undefined &&
                    errors?.agents?.at(index)?.email?.message;
                  return (
                    <TextField
                      {...field}
                      error={e !== undefined && e !== false}
                      helperText={e ? e.toString() : "　"}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-input": { background: "white" },
                      }}
                      type="text"
                      placeholder="Email (Add to receive messages)"
                      inputProps={{
                        maxLength: 50,
                        style: {
                          padding: "10px 12px",
                        },
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={`agents.${index}.prefered_communication`}
                render={({ field }) => {
                  return (
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item xs={12} md={6}>
                        <Typography>Message notification method</Typography>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <FormControlLabel
                          control={
                            <Radio
                              sx={{
                                color: "#DEDEDE",
                              }}
                              checked={field.value === CommunicationType.EMAIL}
                              onChange={(_) =>
                                field.onChange(CommunicationType.EMAIL)
                              }
                            />
                          }
                          label="Email"
                        />
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <FormControlLabel
                          control={
                            <Radio
                              sx={{
                                color: "#DEDEDE",
                              }}
                              checked={field.value === CommunicationType.TEXT}
                              onChange={(_) =>
                                field.onChange(CommunicationType.TEXT)
                              }
                            />
                          }
                          label="SMS"
                        />
                      </Grid>
                    </Grid>
                  );
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={12} md={6}>
                  <Typography>Availability</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Select
                    {...statusField}
                    value={statusValue}
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-input": { background: "white" },
                    }}
                  >
                    <MenuItem key={Status.AVAILABLE} value={Status.AVAILABLE}>
                      Available. Send calls through
                    </MenuItem>
                    <MenuItem key={Status.AWAY} value={Status.AWAY}>
                      Unavailable. Take a message
                    </MenuItem>
                    <MenuItem key={Status.SCHEDULE} value={Status.SCHEDULE}>
                      Schedule. Accept calls based on timetable below
                    </MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>

            {statusValue === Status.SCHEDULE && (
              <Grid item xs={12}>
                <Box ref={accordianRef} sx={{ marginTop: 1 }}>
                  <AvailabilityAccordian
                    index={index}
                    expanded={expanded}
                    onChange={(e, value) => handleExpandChange(value, e)}
                  />
                </Box>
              </Grid>
            )}

            {showRemove && (
              <Grid
                container
                item
                xs={12}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 1,
                  marginBottom: 1,
                }}
                spacing={2}
              >
                <Grid item xs={12} md={3}>
                  <Button
                    sx={{
                      width: isMobile ? "100%" : "180px",
                    }}
                    variant="outlined"
                    onClick={() => handleRemoveAgent()}
                  >
                    Remove team member
                  </Button>
                </Grid>
                {!isMobile && <Grid item xs={0} md={4}></Grid>}
                <Grid item xs={12} md={3} sx={{ textAlign: "right" }}>
                  <Button
                    sx={{
                      width: isMobile ? "100%" : "180px",
                    }}
                    variant="contained"
                    type="submit"
                  >
                    Save changes
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        );
      }}
    />
  );
}
