import { Box, Button, Stack } from "@mui/material";
import { useContext, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import ApiContext from "../../context/ApiContext";
import { IHomeFormInput } from "../../interfaces/IHomeFormInput";
import { ModelCardLayout } from "../ModelCardLayout";
import { ResetCardDialog } from "../dialog/ResetCardDialog";
import { AccessLevel } from "../../interfaces/IGetUserResult";
import AuthContext from "../../context/AuthContext";

export interface ModelCardSectionProps {}

export function ModelCardSection(props: ModelCardSectionProps) {
  const {
    data: { account },
  } = useContext(AuthContext);
  const {
    data: { defaultCards },
  } = useContext(ApiContext);

  const { control, setValue } = useFormContext<IHomeFormInput>();
  useFieldArray({
    control,
    name: "cards",
  });

  const handleReset = () => {
    setValue("cards", defaultCards);
    setDialogOpen(false);
  };
  const [dialogOpen, setDialogOpen] = useState(false);

  const cardsDisabled = !(
    account?.access_level === AccessLevel.PRO ||
    (account?.access_level === AccessLevel.FREE &&
      process.env.REACT_APP_STRIPE_ENABLE !== "true")
  );

  return (
    <>
      <ResetCardDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onReset={handleReset}
      />
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
      >
        <ModelCardLayout disabled={cardsDisabled} />
        <Box sx={{ paddingLeft: 2 }}>
          <Button
            variant="outlined"
            type="button"
            disabled={cardsDisabled}
            onClick={() => setDialogOpen(true)}
          >
            Reset
          </Button>
        </Box>
      </Stack>
    </>
  );
}
