import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import * as yup from "yup";
import { default as AuthContext } from "../context/AuthContext";
import { LoadingCircle } from "../img/LoadingCircle";
import { RightArrow } from "../img/RightArrow";
import { useNavigateToTop, useReferer } from "../utils/utils";
import RegisterEmailSection from "../components/section/RegisterEmailSection";
import { AuthUser } from "../interfaces/AuthUser";
import PlainContainer from "../components/container/PlainContainer";
import { ErrorAlert } from "../components/alert/ErrorAlert";

var owasp = require("owasp-password-strength-test");
interface IFormInput {
  email: string;
  password: string;
  password2: string;
}

const schema = yup
  .object({
    email: yup
      .string()
      .required("Email field is required.")
      .max(50)
      .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/, {
        message: "Please enter a valid email address.",
        excludeEmptyString: true,
      }),
    password: yup
      .string()
      .required("Password field is required.")
      .max(50)
      .test((value, ctx) => {
        var result = owasp.test(value);
        if (result.errors.length > 0) {
          return ctx.createError({
            message: result.errors[0],
          });
        }
        return true;
      }),
    password2: yup
      .string()
      .required("Password field is required.")
      .max(50)
      .oneOf([yup.ref("password")], "Password must match"),
  })
  .required();
interface RegisterViewProps {
  showStore: boolean;
}

export default function RegisterView(props: RegisterViewProps) {
  const navigateToTop = useNavigateToTop();
  const referer = useReferer();
  const {
    data: { account },
  } = useContext(AuthContext);
  const email = account?.username

  const {
    actions: { isLoggedIn, createGuestAccount, updateAccount },
  } = useContext(AuthContext);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const methods = useForm<IFormInput>({
    resolver: yupResolver(schema),
    reValidateMode: "onSubmit",
    defaultValues: {
      email: email,
      password: "",
    },
  });

  const { handleSubmit, setValue, reset } = methods;

  useEffect(() => {
    if (email) setValue("email", email);
  }, [email, setValue]);

  const onSubmit: SubmitHandler<IFormInput> = (data: IFormInput) => {
    const { email, password } = data;

    setError(null);
    setSuccess("");
    setLoading(true);

    const accountFunction = isLoggedIn() ? updateAccount : createGuestAccount;
    accountFunction(email, password, referer)
      .then(async (token: AuthUser) => {
        setError(null);
        reset();
        navigateToTop("/dashboard");
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  if (account?.has_password) return <Navigate to="/" replace={true} />;
  return (
    <PlainContainer>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            direction="column"
            spacing={2}
            justifyContent="center"
            alignItems="stretch"
          >
            <ErrorAlert error={error} />
            {success && <Alert severity="success">{success} </Alert>}
            <Typography variant="h3">
              Complete your account registration
            </Typography>
            <RegisterEmailSection disabledEmail={!!email} />
            <Button sx={{ width: "100%" }} variant="contained" type="submit">
              Create Account {loading ? <LoadingCircle /> : <RightArrow />}
            </Button>
          </Stack>
        </form>
      </FormProvider>
    </PlainContainer>
  );
}
