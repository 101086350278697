import {
  Alert,
  Box,
  Button,
  Dialog,
  Paper,
  Stack,
  TextField,
  Typography, useTheme
} from "@mui/material";
import { useState, useContext, useEffect } from "react";
import AuthContext from "../../context/AuthContext";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMediaMobile } from "../../utils/utils";
import ApiContext from "../../context/ApiContext";
import { object, string } from "yup";
import { useNavigate } from "react-router-dom";

export interface ResetCardDialogProps {
  open: boolean;
  onClose: (reason?: string) => void;
  onReset: () => void;
}

export function ResetCardDialog(props: ResetCardDialogProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaMobile();

  const { open, onClose, onReset } = props;

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <Paper
        sx={{
          width: { xs: 320, sm: 350, },
          padding: theme.spacing(2),
        }}
      >
          <Stack
            direction="column"
            spacing={1}
            justifyContent="center"
            alignItems="stretch"
          >
            <Typography variant="h4" sx={{ textAlign: 'center' }}>Reset Cards</Typography>
            <Box>
              <Typography variant="body1" sx={{ marginBottom: isMobile ? 0 : 1 }}>
              Warning: Clicking reset will remove all of your custom knowledge cards. Are you sure you want to continue?
              </Typography>
              
            </Box>
            <Button sx={{ width: "100%" }} variant="contained" onClick={onReset}  >
              Reset
            </Button>
          </Stack>
      </Paper>
    </Dialog>
  );
}


