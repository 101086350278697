import {
  Button,
  Dialog,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export interface NavBlockerDialogProps {
  open: boolean;
  onClose: () => void;
  onOk: () => void;
  onCancel: () => void;
}

export function NavBlockerDialog(props: NavBlockerDialogProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { open, onClose, onOk, onCancel } = props;

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
      }}
    >
      <Paper
        sx={{
          width: { xs: 320, sm: 350 },
          padding: theme.spacing(2),
        }}
      >
        <Stack
          direction="column"
          spacing={1}
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            Warning
          </Typography>
          <Typography
            variant="body1"
          >
            Are you sure you want to navigate away from this page? You may lose your pending changes.
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
            sx={{ width: "100%" }}
          >
            <Button
              sx={{ width: "100%" }}
              variant="outlined"
              type="button"
              onClick={onCancel}
            >
              Stay on this page
            </Button>
            <Button
              sx={{ width: "100%" }}
              variant="contained"
              type="button"
              onClick={onOk}
            >
              leave this page
            </Button>
          </Stack></Stack>
      </Paper>
    </Dialog>
  );
}
