import {
  Alert,
  Button,
  Dialog,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useContext } from "react";
import AuthContext from "../../context/AuthContext";
import { RightArrow } from "../../img/RightArrow";
import { LoadingCircle } from "../../img/LoadingCircle";
import { HideIcon } from "../../img/HideIcon";
import { ShowIcon } from "../../img/ShowIcon";
import * as yup from "yup";
import { theme } from "../../theme";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useReferer } from "../../utils/utils";
import { ErrorAlert } from "../alert/ErrorAlert";
var owasp = require("owasp-password-strength-test");

interface IFormInput {
  email: string;
  password: string;
}

const schema = yup
  .object({
    email: yup
      .string()
      .required("Email field is required.")
      .max(50)
      .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/, {
        message: "Please enter a valid email address.",
        excludeEmptyString: true,
      }),
    password: yup
      .string()
      .required("Password field is required.")
      .max(50)
      .test((value, ctx) => {
        var result = owasp.test(value);
        if (result.errors.length > 0) {
          return ctx.createError({
            message: result.errors[0],
          });
        }
        return true;
      }),
  })
  .required();

export interface RegisterDialogProps {
  open: boolean;
  onClose: (reason?: string) => void;
}

export function RegisterDialog(props: RegisterDialogProps) {
  const referer = useReferer();

  const { open, onClose } = props;
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onSubmit",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const {
    actions: { updateAccount },
  } = useContext(AuthContext);

  const onSubmit: SubmitHandler<IFormInput> = (data: IFormInput) => {
    const { email, password } = data;

    setError(null);
    setSuccess("");
    setLoading(true);
    updateAccount(email, password, referer)
      .then((response) => {
        setError(null);
        reset();
        onClose();
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        reset();
        onClose();
      }}
    >
      <Paper
        sx={{
          width: { xs: 305, sm: 400, md: 500, lg: 500 },
          padding: theme.spacing(2),
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            direction="column"
            spacing={2}
            justifyContent="center"
            alignItems="stretch"
          >
            <ErrorAlert error={error} />
            {success && <Alert severity="success">{success} </Alert>}

            <Typography variant="h4">Email</Typography>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.email !== undefined}
                  helperText={errors.email ? errors.email?.message : "　"}
                  className="rounded-md w-full !ring-transparent !outline-none focus:outline-none bg-white/10 focus:border-white/80 transition-all focus:bg-white/5"
                  type="text"
                  inputProps={{
                    style: {
                      padding: "10px 12px",
                    },
                  }}
                />
              )}
            />
            <Typography variant="h4">Password</Typography>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.password !== undefined}
                  helperText={errors.password ? errors.password?.message : "　"}
                  className="rounded-md w-full !ring-transparent !outline-none focus:outline-none bg-white/10 focus:border-white/80 transition-all focus:bg-white/5"
                  type={passwordShown ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => setPasswordShown(!passwordShown)}
                        >
                          {!passwordShown ? <ShowIcon /> : <HideIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    style: {
                      padding: "10px 12px",
                    },
                  }}
                />
              )}
            />
            <Button sx={{ width: "100%" }} variant="contained" type="submit">
              Create Account {loading ? <LoadingCircle /> : <RightArrow />}
            </Button>
          </Stack>
        </form>
      </Paper>
    </Dialog>
  );
}
