import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  Dialog,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { object, string } from "yup";
import ApiContext from "../../context/ApiContext";
import { LoadingCircle } from "../../img/LoadingCircle";
import { RightArrow } from "../../img/RightArrow";
import { theme } from "../../theme";
import { ErrorAlert } from "../alert/ErrorAlert";

interface IFormInput {
  name: string;
  subject: string;
  body: string;
}

const schema = object({
  name: string().required("First name is required."),
  subject: string().required("Subject is required."),
  body: string().required("Message is required."),
}).required();

export interface SubmitTicketDialogProps {
  open: boolean;
  onClose: (reason?: string) => void;
}

export function SubmitTicketDialog(props: SubmitTicketDialogProps) {
  const { open, onClose } = props;
  const {
    actions: { createTicket },
  } = useContext(ApiContext);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onSubmit",
    defaultValues: {
      name: "",
      subject: "",
      body: "",
    },
  });

  const onSubmit: SubmitHandler<IFormInput> = (data: IFormInput) => {
    const { name, subject, body } = data;
    setError(null);
    setSuccess("");
    setLoading(true);
    createTicket(name, subject, body)
      .then(() => {
        setError(null);
        reset();

        setSuccess("Ticket Created");

        setTimeout(() => {
          onClose();
        }, 2000);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        reset();
        onClose();
      }}
    >
      <Paper
        sx={{
          width: { xs: 320, sm: 400, md: 500, lg: 500 },
          padding: theme.spacing(2),
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            direction="column"
            spacing={0.5}
            justifyContent="center"
            alignItems="stretch"
          >
            <ErrorAlert error={error} />
            {success && <Alert severity="success">{success} </Alert>}

            <Typography variant="h4">Submit Ticket</Typography>
            <Typography variant="body1">Name</Typography>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.name !== undefined}
                  helperText={errors.name ? errors.name?.message : "　"}
                  className="rounded-md w-full !ring-transparent !outline-none focus:outline-none bg-white/10 focus:border-white/80 transition-all focus:bg-white/5"
                  type="text"
                  inputProps={{
                    style: {
                      padding: "10px 12px",
                    },
                  }}
                />
              )}
            />
            <Typography variant="body1">Subject</Typography>
            <Controller
              name="subject"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.subject !== undefined}
                  helperText={errors.subject ? errors.subject?.message : "　"}
                  className="rounded-md w-full !ring-transparent !outline-none focus:outline-none bg-white/10 focus:border-white/80 transition-all focus:bg-white/5"
                  type="text"
                  inputProps={{
                    style: {
                      padding: "10px 12px",
                    },
                  }}
                />
              )}
            />
            <Typography variant="body1">Message</Typography>
            <Controller
              name="body"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.body !== undefined}
                  helperText={errors.body ? errors.body?.message : "　"}
                  sx={{ "& .MuiOutlinedInput-root": { background: "white" } }}
                  type="text"
                  multiline
                  rows={5}
                  data-no-dnd="true"
                />
              )}
            />
            <Button sx={{ width: "100%" }} variant="contained" type="submit">
              Submit {loading ? <LoadingCircle /> : <RightArrow />}
            </Button>
          </Stack>
        </form>
      </Paper>
    </Dialog>
  );
}
