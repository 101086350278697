import { Alert } from "@mui/material";
import {
  showFriendlyErrorFromHTMLCode,
  useNavigateToTop,
} from "../../utils/utils";
import { ErrorCode } from "../../interfaces/IErrorCode";

export interface ErrorAlertProps {
  error?: any;
}

const parseError = (error: any) => {
  if (error?.response?.data?.detail?.length > 0) {
    return error?.response?.data?.detail[0];
  }
  return null;
};

const msgFromError = (error: any) => {
  const err = parseError(error);
  if (err && err.type === ErrorCode.BLACKLIST)
    return (
      <>
        Unfortunately, calls to this destination are not supported yet.{" "}
        <u>Click here</u> to understand why.
      </>
    );
  if (err && err.type === ErrorCode.USER_EXIST)
    return (
      <>
        A user with this email already exists. If you are having trouble
        accessing your account, <u>click here</u> to reset your password
      </>
    );
  return null;
};

export function ErrorAlert(props: ErrorAlertProps) {
  const navigateToTop = useNavigateToTop();

  const actionFromError = (error: any) => {
    const err = parseError(error);
    if (err && err.type === ErrorCode.BLACKLIST)
      return () => window.open("/faqs#blocked-tn", "_blank");
    if (err && err.type === ErrorCode.USER_EXIST)
      return () => {
        navigateToTop("/forgot-password");
      };
    return null;
  };

  const { error } = props;

  let msg: any = msgFromError(error);
  if (!msg) msg = showFriendlyErrorFromHTMLCode(error);

  const action = actionFromError(error);

  if (error && msg)
    return (
      <Alert
        onClick={action ? action : undefined}
        sx={{ cursor: action ? "pointer" : undefined }}
        severity="error"
      >
        {msg}
      </Alert>
    );
}
