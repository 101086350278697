import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import { useContext } from "react";
import ApiContext from "../context/ApiContext";
import AuthContext from "../context/AuthContext";
import { Box, CircularProgress } from "@mui/material";
const apiKey = process.env.REACT_APP_STRIPE_API_KEY;

const properties = {
  display: "grid",
  placeItems: "center",
  height: "100vh",
};
export default function StoreView() {
  const {
    data: { account },
    actions: { isLoggedIn },
  } = useContext(AuthContext);

  const {
    actions: { createStoreSession },
  } = useContext(ApiContext);

  if (!isLoggedIn())
    return (
      <Box sx={properties}>
        <CircularProgress size={50} />
      </Box>
    );

  const email = account?.username;

  const fetchClientSecret = (): Promise<string> => {
    return createStoreSession(
      !isLoggedIn() && email && email.length > 0
        ? `${window.location.origin}/complete`
        : `${window.location.origin}/dashboard?sessionid={CHECKOUT_SESSION_ID}`
    );
  };
  const loadStripePromise = async (): Promise<Stripe | null> => {
    if (apiKey) {
      const stripePromise = await loadStripe(apiKey);
      return stripePromise;
    } else {
      console.log("MISSING STRIPE_API_KEY");
      return null;
    }
  };
  const options = { fetchClientSecret };
  return (
    <EmbeddedCheckoutProvider stripe={loadStripePromise()} options={options}>
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  );
}
