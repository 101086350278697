import {
  Button,
  Dialog,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import EmojiFoodBeverageIcon from '@mui/icons-material/EmojiFoodBeverage';

export interface AreYourThereDialogProps {
  open: boolean;
  onClose: (reason?: string) => void;
}

export function AreYourThereDialog(props: AreYourThereDialogProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { open, onClose } = props;

  return (
    <Dialog
      open={open}
      onClose={() => {
        
      }}
    >
      <Paper
        sx={{
          width: { xs: 320, sm: 350 },
          padding: theme.spacing(2),
        }}
      >
          <Stack
            direction="column"
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              Are you still there?
            </Typography>
            <Typography
                variant="body1"
              >
               Please sign in below to continue using Nucleus
              </Typography>
              
              <EmojiFoodBeverageIcon sx={{ width: 100, height: 100 }} />
            <Button
              sx={{ width: "100%" }}
              variant="contained"
              type="button"
              onClick={()=>navigate("/signin")}
            >
              Sign in
            </Button>
          </Stack>
      </Paper>
    </Dialog>
  );
}
