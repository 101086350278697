import "./App.css";
import { ReactElement, useContext } from "react";
import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route, RouterProvider, ScrollRestoration } from "react-router-dom";
import LandingView from "./views/LandingView";
import SigninView from "./views/SigninView";
import AppLayout from "./layouts/AppLayout";
import HomeView from "./views/HomeView";
import PublicRoutes from "./utils/PublicRoutes";
import AuthLayout from "./layouts/AuthLayout";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AuthContext, { AuthContextProvider } from "./context/AuthContext";
import { ApiContextProvider } from "./context/ApiContext";
import { ThemeProvider } from "@mui/material";
import { innerTheme, theme } from "./theme";
import ForgotPasswordView from "./views/ForgotPasswordView";
import ResetPasswordView from "./views/ResetPasswordView";
import StoreView from "./views/StoreView";
import StorePortalView from "./views/StorePortalView";
import RegisterView from "./views/RegistrationView";
import { TrackingContextProvider } from "./context/TrackingContext";
import CrispChat from "./components/crisp/crisp";
import StoreCartView from "./views/StoreCartView";
import FAQsView from "./views/FAQs";
import TermsView from "./views/TermsOfUse";
import PrivacyView from "./views/Privacy";
import NotFoundView from "./views/NotFoundView";
import SignupView from "./views/SignupView";
import EmailVerifyView from "./views/VerifyEmailView";
import React from "react";
import RegistrationEmbed from "./views/RegistrationEmbed";

interface ViewWrapperProps {
  element: ReactElement;
  redirect?: boolean;
}

function ViewWrapper(props: ViewWrapperProps) {
  const { element, redirect } = props;
  const {
    data: { authToken },
    actions: { isLoggedIn },
  } = useContext(AuthContext);

  if (isLoggedIn())
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {element}
      </LocalizationProvider>
    );
  if (authToken !== undefined)
    if (redirect) return <Navigate to="/" replace={true} />;
  return <LandingView />;
}


const WrapperComponent = (
) => {
  return (<AuthContextProvider>
    <ApiContextProvider>
      <TrackingContextProvider>
        <Outlet />
        <CrispChat />
        <ScrollRestoration/>
      </TrackingContextProvider>
    </ApiContextProvider>
  </AuthContextProvider>)
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<WrapperComponent />}>
      <Route element={<AppLayout />}>
        <Route path='*' element={<NotFoundView />} />
        <Route
          path="/"
          element={<LandingView />}
        />
        <Route
          path="/dashboard"
          element={<ViewWrapper redirect={true} element={<HomeView />} />}
        />
        <Route path="/signup" element={<SignupView />} />
        <Route path="/faqs" element={<FAQsView />} />
        <Route path="/terms" element={<TermsView />} />
        <Route path="/privacy" element={<PrivacyView />} />
        <Route path="/billing" element={<StorePortalView />} />
        <Route path="/upgrade" element={<StoreCartView />} />
      </Route>
      <Route
        path="/complete"
        element={
          <ViewWrapper
            redirect
            element={<RegisterView showStore={false} />}
          />
        }
      />

      <Route path="/register" element={<RegisterView showStore />} />
      <Route path="/reg" element={<RegistrationEmbed />} />
      <Route element={<PublicRoutes />}>
        <Route element={<AuthLayout />}>
          <Route path="/signin" element={<SigninView />} />
          <Route
            path="/forgot-password"
            element={<ForgotPasswordView />}
          />

        </Route>
      </Route>
      <Route
        path="/reset-password"
        element={<ResetPasswordView />}
      />
      <Route
        path="/verify"
        element={<EmailVerifyView />}
      />
      <Route path="/store" element={<StoreView />} />

      <Route path="/ai" element={<Navigate to="/" replace={true} />} />
      <Route path="/whats-new" element={<Navigate to="/" replace={true} />} />
      <Route path="/products" element={<Navigate to="/" replace={true} />} />
      <Route path="/nucleus-web-services" element={<Navigate to="/" replace={true} />} />
    </Route>
  )
);


function App() {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <ThemeProvider theme={innerTheme}>
          <RouterProvider router={router} />
         
        </ThemeProvider>
      </ThemeProvider>
      
    </React.StrictMode>
  );
}

export default App;
