import React, { useCallback, useEffect, useState } from "react";
import { AuthUser } from "../interfaces/AuthUser";
import { default as ApiService } from "../context/ApiServices";
import { IAgentCreate, TeamMember } from "../interfaces/Agent";
import { jwtDecode } from "jwt-decode";
import { ICityResult } from "../interfaces/ICityResult";
import { useSearchParams } from "react-router-dom";
import {
  STORAGE_ACCOUNT_DATA,
  STORAGE_AUTH_DATA,
  STORAGE_COMPANY_DATA,
  STORAGE_HOME_FORM,
} from "../const/LocalStorageKeys";
import {
  AccessLevel,
  IAccount,
  ICompany,
  IGetUserResult,
} from "../interfaces/IGetUserResult";
import dayjs, { Dayjs } from "dayjs";
import useLocalStorage from "../hook/useLocalStorage";
import { CardItem } from "../interfaces/CardItem";

const AuthContext = React.createContext<{
  actions: {
    login: (username: string, password: string) => Promise<any>;
    logout: () => void;
    createGuest: (
      email: string,
      location: ICityResult,
      businessName: string,
      voice: string,
      agents: IAgentCreate[],
      referer?: string
    ) => Promise<any>;
    createGuestAccount: (
      username: string,
      password: string,
      referer?: string
    ) => Promise<any>;
    updateAccount: (
      username: string,
      password?: string,
      referer?: string
    ) => Promise<any>;
    deleteAccount: () => Promise<void>;
    forgotPassword: (email: string) => Promise<any>;
    resetPassword: (password: string, token: string) => Promise<any>;
    verifyEmail: (email: string, token: string) => Promise<any>;
    resendVerifyEmail: (email: string) => Promise<any>;
    isLoggedIn: (t?: AuthUser | null | undefined) => boolean;
    expireAt: (authToken: AuthUser | null | undefined) => number;
    updateUser: (
      businessName: string,
      voice: string,
      cards: CardItem[],
      agents: TeamMember[],
      welcome_message: string | undefined,
      goodbye_message: string | undefined,
      time_zone: string
    ) => Promise<IGetUserResult>;
    syncStripe: () => Promise<any>;
  };
  data: {
    authToken: AuthUser | null | undefined;
    account: IAccount | null;
    company: ICompany | null;
    trial_start: Dayjs | null;
    trial_end: Dayjs | null;
  };
}>({
  actions: {
    login: async () => undefined,
    logout: () => undefined,
    createGuest: async () => undefined,
    createGuestAccount: async () => undefined,
    updateAccount: async () => undefined,
    deleteAccount: async () => undefined,
    forgotPassword: async () => undefined,
    resetPassword: async () => undefined,
    verifyEmail: async () => undefined,
    resendVerifyEmail: async () => undefined,
    isLoggedIn: (t?: AuthUser | null | undefined) => false,
    expireAt: (_) => -1,
    updateUser: async () => ({
      account: {
        id: -1,
        username: "",
        access_level: AccessLevel.GUEST,
        has_password: false,
        verified: false,
      },
      company: {
        id: -1,
        did: "",
        name: "",
        voice: "",
        users: [],
        cards: [],
      },
    }),
    syncStripe: async () => undefined,
  },
  data: {
    authToken: undefined,
    account: null,
    company: null,
    trial_start: null,
    trial_end: null,
  },
});

interface AuthContextProviderProps {
  children: React.ReactNode;
}

const checkToken = (token?: AuthUser | null | undefined) => {
  if (!token) return false;

  const exp = expireAt(token);
  if (exp < 0 || Date.now() >= exp) {
    return false;
  }
  return true;
};

const expireAt = (token: AuthUser | null | undefined) => {
  if (token) {
    try {
      const decoded = jwtDecode(token.access_token);
      if (decoded && decoded.exp) return decoded.exp * 1000;
    } catch (e) {
      console.log(e);
    }
  }
  return -1;
};

const loadToken = (searchParams: URLSearchParams) => {
  const query = searchParams.get("token");
  const json = localStorage.getItem(STORAGE_AUTH_DATA);
  if (query) {
    try {
      const token: AuthUser = {
        access_token: query,
        token_type: "bearer",
      };
      if (checkToken(token)) return token;
    } catch (e) {}
  }
  if (json) {
    const token = JSON.parse(json);
    if (checkToken(token)) return token;
  }

  clearStorage();
  return null;
};

const clearStorage = () => {
  localStorage.removeItem(STORAGE_AUTH_DATA);
  localStorage.removeItem(STORAGE_ACCOUNT_DATA);
  localStorage.removeItem(STORAGE_COMPANY_DATA);
  sessionStorage.removeItem(STORAGE_HOME_FORM);
};

export function AuthContextProvider(
  props: AuthContextProviderProps
): React.ReactElement {
  const { children } = props;
  const [searchParams] = useSearchParams();
  const [authToken, setAuthToken] = useState<AuthUser | null>(() =>
    loadToken(searchParams)
  );
  const [account, setAccount] = useLocalStorage<IAccount | null>(
    STORAGE_ACCOUNT_DATA,
    null
  );
  const [company, setCompany] = useLocalStorage<ICompany | null>(
    STORAGE_COMPANY_DATA,
    null
  );
  const [trial_start, setTrialStart] = useState<Dayjs | null>(null);
  const [trial_end, setTrialEnd] = useState<Dayjs | null>(null);

  const isLoggedIn = useCallback(
    (t?: AuthUser | null | undefined) => {
      const token = t ? t : authToken;
      return checkToken(token);
    },
    [authToken]
  );

  const logout = useCallback(() => {
    setAuthToken(null);
    clearStorage();
  }, []);

  const setUserResult = useCallback(
    (data: IGetUserResult) => {
      setAccount(data.account);

      const newCompany = data.company;
      newCompany.cards = data.company.cards
        .map((card) => ({ ...card }))
        .sort((a, b) => a.sort_order - b.sort_order);
      newCompany.users = data.company.users.map((agent) => {
        return { ...agent, phone: agent.phone.slice(2) };
      });
      setCompany(newCompany);

      if (data.stripe?.trial_start)
        setTrialStart(dayjs.utc(data.stripe?.trial_start));
      else setTrialStart(null);

      if (data.stripe?.trial_end)
        setTrialEnd(dayjs.utc(data.stripe?.trial_end));
      else setTrialEnd(null);
    },
    [setAccount, setCompany]
  );

  const getUser = useCallback(
    (t?: AuthUser) => {
      return new Promise<IGetUserResult>(async (resolve, reject) => {
        const token = t ? t : authToken;
        if (!isLoggedIn(token)) {
          reject("You are not logged in. Please log in and try again");
        }
        try {
          const response = await ApiService.api.get(`/user`, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${token?.access_token}`,
            },
          });

          setUserResult(response.data);
          resolve(response.data);
        } catch (e: any) {
          logout();
          reject(e);
        }
      });
    },
    [authToken, isLoggedIn, logout, setUserResult]
  );

  const setTokenResponse = useCallback(
    async (data: AuthUser) => {
      const token: AuthUser = data;
      await getUser(token);
      setAuthToken(token);
      localStorage.setItem(STORAGE_AUTH_DATA, JSON.stringify(token));
      sessionStorage.removeItem(STORAGE_HOME_FORM);
    },
    [getUser]
  );

  const login = useCallback(
    (username: string, password: string) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiService.api.post(
            `/login`,
            {
              username,
              password,
            },
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          );
          const token = response.data;

          await setTokenResponse(token);
          resolve(response);
        } catch (e) {
          logout();
          reject(e);
        }
      });
    },
    [logout, setTokenResponse]
  );

  const createGuest = useCallback(
    (
      email: string,
      location: ICityResult,
      businessName: string,
      voice: string,
      agents: IAgentCreate[],
      referer?: string
    ) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiService.api.post(
            `/user`,
            {
              username: email,
              location: location,
              name: businessName,
              voice: voice,
              users: agents,
              referer,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          await setTokenResponse(response.data);
          resolve(response);
        } catch (e: any) {
          reject(e);
        }
      });
    },
    [setTokenResponse]
  );

  const createGuestAccount = useCallback(
    (username: string, password: string, referer?: string) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiService.api.post(
            `account`,
            { username, password, referer },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const token: AuthUser = response.data;
          await setTokenResponse(token);
          resolve(token);
        } catch (e: any) {
          reject(e);
        }
      });
    },
    [setTokenResponse]
  );

  const updateAccount = useCallback(
    (username: string, password?: string, referer?: string) => {
      return new Promise(async (resolve, reject) => {
        if (!isLoggedIn()) {
          reject("You are not logged in. Please log in and try again");
        }
        try {
          const response = await ApiService.api.put(
            `account`,
            { username, password, referer },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken?.access_token}`,
              },
            }
          );
          await setTokenResponse(response.data);
          resolve(response);
        } catch (e: any) {
          reject(e);
        }
      });
    },
    [authToken, isLoggedIn, setTokenResponse]
  );

  const deleteAccount = useCallback(() => {
    return new Promise<void>(async (resolve, reject) => {
      if (!isLoggedIn()) {
        reject("You are not logged in. Please log in and try again");
      }
      try {
        await ApiService.api.delete(`account`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken?.access_token}`,
          },
        });
        logout();
        resolve();
      } catch (e: any) {
        reject(e);
      }
    });
  }, [authToken, isLoggedIn, logout]);

  const forgotPassword = useCallback((email: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ApiService.api.post(
          `forgot-password`,
          { email },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        resolve(response);
      } catch (e: any) {
        reject(e);
      }
    });
  }, []);

  const resetPassword = useCallback(
    (password: string, token: string) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiService.api.post(
            `reset-password`,
            { new_password: password, token },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          await setTokenResponse(response.data);
          resolve(response);
        } catch (e: any) {
          reject(e);
        }
      });
    },
    [setTokenResponse]
  );

  const resendVerifyEmail = useCallback((email: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ApiService.api.put(
          `verify-email`,
          { email: email },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        resolve(response);
      } catch (e: any) {
        reject(e);
      }
    });
  }, []);

  const verifyEmail = useCallback(
    (email: string, token: string) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiService.api.post(
            `verify-email`,
            { email: email, token },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          await setTokenResponse(response.data);
          resolve(response.data);
        } catch (e: any) {
          reject(e);
        }
      });
    },
    [setTokenResponse]
  );

  const updateUser = useCallback(
    (
      businessName: string,
      voice: string,
      cards: CardItem[],
      agents: TeamMember[],
      welcome_message: string | undefined,
      goodbye_message: string | undefined,
      time_zone: string
    ) => {
      return new Promise<IGetUserResult>(async (resolve, reject) => {
        if (!isLoggedIn()) {
          reject("You are not logged in. Please log in and try again");
        }
        try {
          const response = await ApiService.api.put(
            `user`,
            {
              name: businessName,
              voice: voice,
              cards: cards,
              users: agents,
              welcome_message: welcome_message,
              goodbye_message: goodbye_message,
              time_zone: time_zone,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken?.access_token}`,
              },
            }
          );
          setUserResult(response.data);
          resolve(response.data);
        } catch (e: any) {
          reject(e);
        }
      });
    },
    [authToken, isLoggedIn, setUserResult]
  );

  const syncStripe = useCallback(() => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const token = authToken;
        const response = await ApiService.api.put(
          `stripe/sync`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token?.access_token}`,
            },
          }
        );
        const newAccount = response.data;
        setAccount(newAccount);
        resolve(response.data);
      } catch (e: any) {
        reject(e);
      }
    });
  }, [authToken, setAccount]);

  useEffect(() => {
    if (isLoggedIn()) getUser();
  }, [getUser, isLoggedIn]);

  const [ssync, setSync] = useState<boolean>(false);
  useEffect(() => {
    if (searchParams.get("sessionid") && isLoggedIn() && !ssync) {
      for (let i = 0; i <= 3; i++) {
        setSync(true);
        setTimeout(
          () => {
            if (isLoggedIn()) syncStripe();
          },
          (2 ^ (i - 1)) * 3000 + 500
        );
      }
    }
  }, [isLoggedIn, searchParams, ssync, syncStripe]);

  return (
    <AuthContext.Provider
      value={{
        actions: {
          login,
          logout,
          createGuest,
          createGuestAccount,
          updateAccount,
          deleteAccount,
          forgotPassword,
          resetPassword,
          verifyEmail,
          resendVerifyEmail,
          isLoggedIn,
          expireAt,
          updateUser,
          syncStripe,
        },
        data: {
          authToken,
          account,
          company,
          trial_start,
          trial_end,
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
export default AuthContext;
