import {
  Box,
  Grid,
  Link,
  Stack,
  Typography
} from "@mui/material";

import LandingViewForm from "../components/LandingViewForm";
import { NucleusButton } from "../components/NucleusButton";
import { useEffect } from "react";


export default function SignupView() {


  return (
    <Box sx={{ margin: "auto" }}>
      <Grid container margin={0} padding={2}>

        <Grid item xs={0} md={6}>
          <Stack component="section" direction="column" padding={2}>
            <Box className="text" >
              <Typography variant="h1">Your AI Attendant</Typography>
              <Typography variant="h2">Hi! I'm Alice. Configure me in 30 seconds.</Typography>
              <Typography variant="body1">
                I'm the voice at the center of your business. Right now I can be your receptionist - I can answer your call, transfer your call, and if you're not available,
                I can email or text you the message from your caller. In the future, I will be able to do much more - like scheduling, booking appointments, and reminder calls to customers.
                My founders will soon bring in Diego, Bob, Maria, and others who can also do these tasks, and we can work together for you as a team.
              </Typography>
              <Typography variant="body1">
                I can start work right away! And I'm free!
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack component="section" direction="column" padding={2} id="activatealice">
            <Box className="text" >
              <Typography variant="body1">
                Receive a dedicated business phone number and your Al attendant, Alice. <strong>No credit card or app download required.</strong> Activate your  <strong>free Starter</strong> account below
              </Typography>
              <Box className="text" >
                <LandingViewForm />
              </Box>
            </Box>
          </Stack>
        </Grid>

        <Stack component="section" className="blue" direction="row" sx={{ width: '100%' }}>
          <article>
            <h1>What I can do now for free</h1>
            <div className="list">
              <ul className="features">
                <li>Provide a new US or Canadian phone number for your business</li>
                <li>Add up to 10 team members to your company directory</li>
                <li>Transfer calls to any team member in the US or Canada*</li>
                <li>Interpret nuances in your callers' requests</li>
                <li>Send messages via text or email outside of office hours</li>
              </ul>
            </div>
          </article>
          <article>
            <h1>What I can also do for Premium users</h1>
            <div className="list">
              <ul className="features">
                <li>Communicate a custom greeting and end call message</li>
                <li>Answer questions based on your business using knowledge cards</li>
                <li>Transfer your existing business number</li>
                <li>Provide more advanced voice models for more natural responses</li>
              </ul>
            </div>
          </article>
        </Stack>
      </Grid>
    </Box>
  );
}
