export const Beta = (props: {
  color?: string;
  size?: number;
  strokeWidth?: number;
}) => {
  return (
<svg width="20" height="20" version="1.1" viewBox="0 0 5.2916 5.2916" xmlns="http://www.w3.org/2000/svg">
 <path transform="matrix(.26458 0 0 .26458 -1.295e-5 0)" d="m19.973 2.498-17.584 17.479h10.869l6.7148-6.6738v-10.805z" fill="#3b4573" stroke-width="0"/>
 <g transform="matrix(0 -.9549 .96072 0 -2.5081 5.9562)">
  <g transform="rotate(45)" fill="#fff" stroke-width=".26458" aria-label="Beta">
   <path d="m5.3854 3.0528q0 0.15425-0.099994 0.23642-0.099219 0.08139-0.26122 0.08139h-0.38137v-1.1286h0.33331q0.17131 0 0.26975 0.070538 0.098444 0.070538 0.098444 0.22402 0 0.086816-0.047284 0.15193-0.046509 0.065112-0.131 0.093018 0.099994 0.021704 0.15968 0.097668 0.059686 0.075189 0.059686 0.17363zm-0.64725-0.72941v0.42401h0.24727q0.1248 0 0.19379-0.053485 0.068988-0.05426 0.068988-0.1589 0-0.20619-0.2589-0.21162zm0.55113 0.73096q0-0.096118-0.063562-0.16123-0.062787-0.065888-0.19456-0.065888h-0.29301v0.46276h0.28603q0.1248 0 0.19456-0.062787 0.070538-0.062787 0.070538-0.17286z"/>
   <path d="m6.2156 3.2373q-0.041083 0.061237-0.11317 0.10542-0.072089 0.043408-0.18448 0.043408-0.16356 0-0.26975-0.1155-0.1062-0.1155-0.1062-0.29611v-0.033331q0-0.19379 0.10775-0.30928 0.10852-0.1155 0.25192-0.1155 0.15968 0 0.24572 0.10852 0.086041 0.10775 0.086041 0.27518v0.056586h-0.59841v0.017828q0 0.13875 0.079065 0.23642 0.07984 0.096893 0.20774 0.096893 0.14805 0 0.23487-0.11472zm-0.31393-0.64182q-0.1062 0-0.17751 0.077515-0.070538 0.077515-0.085266 0.20464h0.50152v-0.010077q-0.0023254-0.10542-0.062012-0.18836-0.058911-0.083716-0.17673-0.083716z"/>
   <path d="m6.7474 3.3737q-0.035657 0.012402-0.099219 0.012402-0.080615 0-0.13177-0.046509-0.05116-0.047284-0.05116-0.17363v-0.5581h-0.15348v-0.075964h0.15348v-0.21549h0.093793v0.21549h0.17286v0.075964h-0.17286v0.55811q0 0.088367 0.032556 0.11395 0.033331 0.024805 0.075964 0.024805 0.031006 0 0.075964-0.00853z"/>
   <path d="m7.4551 3.3706q-0.014728-0.041858-0.018603-0.11705-0.041858 0.053485-0.11317 0.093018-0.071313 0.039532-0.17053 0.039532-0.12325 0-0.19689-0.067438-0.073639-0.068213-0.073639-0.17053 0-0.12325 0.10232-0.19456 0.10232-0.071314 0.27983-0.071314h0.17131v-0.094568q0-0.089917-0.055811-0.14185-0.05581-0.051935-0.16046-0.051935-0.097668 0-0.16123 0.049609t-0.063562 0.12015l-0.093018-7.751e-4q0-0.094568 0.089142-0.17053 0.089142-0.075964 0.23254-0.075964 0.1341 0 0.21937 0.068213 0.086041 0.068213 0.086041 0.20464v0.38757q0 0.1248 0.02558 0.18371v0.010077zm-0.29223-0.066663q0.10077 0 0.17131-0.048059 0.071314-0.048059 0.10154-0.11705v-0.18526h-0.16356q-0.1403 7.751e-4 -0.21937 0.050384-0.07829 0.049609-0.07829 0.13643 0 0.068213 0.050385 0.11627 0.050385 0.047284 0.13798 0.047284z"/>
  </g>
 </g>
</svg>
  );
};
