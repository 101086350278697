import {
  Button,
  Card,
  CardContent,
  CardProps,
  Stack,
  Typography,
} from "@mui/material";
import { AddPlus } from "../img/AddPlus";

export interface ModelAddCardProps extends CardProps {
  disabled?: boolean;
  onCardAdd: () => void;
}

export function ModelAddCard(props: ModelAddCardProps) {
  const { disabled, onCardAdd } = props;

  return (
    <Card
      sx={{
        backgroundColor: "#F8FAFE",
        cursor: "pointer",
      }}
    >
      <CardContent>
        <Button
          onClick={onCardAdd}
          disabled={disabled}
          sx={{
            height: 367,
            width: "100%",
          }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <AddPlus color={disabled ? "#999999" : undefined} />
            <Typography sx={{ color: disabled ? "#999999" : undefined }}>
              Add Card
            </Typography>
          </Stack>
        </Button>
      </CardContent>
    </Card>
  );
}
