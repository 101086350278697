import { Box, Paper, Stack } from "@mui/material";
import { NewtCloudLogo } from "../img/NewtCloudLogo";
import { theme } from "../theme";
import PricingPage from "./PricingPage";
import { Link as RouterLink } from "react-router-dom";
export default function StoreView() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100dvh"
    >
      <Stack
        direction="column"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <div className="chakra-image css-16duawu">
          <RouterLink to="/dashboard">
            <Box sx={{ height: "75px" }}>
              <NewtCloudLogo />
            </Box>
          </RouterLink>
        </div>
        <Paper
          sx={{
            width: { xs: 340, sm: 400, md: 800, lg: 800 },
            padding: theme.spacing(2),
          }}
        >
          <PricingPage />
        </Paper>
      </Stack>
    </Box>
  );

}