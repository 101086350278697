import {
  Typography,
  useTheme
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";


interface NucleusTextLinkProps {
  to: string;
  label: string;
}
export function NucleusTextLink(props: NucleusTextLinkProps) {
  const theme = useTheme();
  const { to, label } = props;

  return (
    <RouterLink to={to}>
      <Typography sx={{
        color: theme.palette.primary.main,
        textDecoration: "underline",
        display: "inline"
      }}>
        {label}
      </Typography>
    </RouterLink>
  );
}
