import { Grid, MenuItem, Select } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import { useEffect, useState } from "react";
import {
  allTimezones,
  ITimezoneOption,
  useTimezoneSelect,
} from "react-timezone-select";
import { TimePicker } from "@mui/x-date-pickers";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

interface TimezonePickerProps {
  value?: string;
  label: string;
  showTz: boolean;
  disabled: boolean;
  onChange: (value?: string) => void;
}

export function TimezonePicker(props: TimezonePickerProps) {
  const { value, label, showTz, disabled, onChange } = props;

  const labelStyle = "original";
  const { options } = useTimezoneSelect({
    labelStyle,
    displayValue: "UTC",
    timezones: allTimezones,
  });

  const filterOptions: ITimezoneOption[] = [];
  for (const option of options) {
    const tz = filterOptions.find((o) => option.offset === o.offset);
    if (tz === undefined) {
      filterOptions.push(option);
    }
  }

  const getDateValue = (value: string | undefined): Dayjs | null => {
    if (dayjs(value, "HH:mm ZZ").isValid()) return dayjs(value, "HH mm ZZ");
    return null;
  };

  const getZoneValue = (value: string | undefined): string | null => {
    let offset: number | undefined;
    let foundOption;
    if (value) {
      offset = dayjs().utcOffset(value.substring(5)).utcOffset() / 60;
      foundOption = filterOptions.find((o) => o.offset === offset);
      if (foundOption) return foundOption.value;
    }

    let tzLocal = dayjs.tz.guess();
    offset = dayjs().tz(tzLocal).utcOffset() / 60;
    foundOption = filterOptions.find((o) => o.offset === offset);
    if (foundOption) return foundOption.value;

    return filterOptions[0].value;
  };

  const [timePart, setTimePart] = useState(getDateValue(value));
  const [zonePart, setZonePart] = useState(getZoneValue(value));

  useEffect(() => {
    let value;
    if (timePart && zonePart)
      value = dayjs.tz(timePart, zonePart!).format("HH:mm ZZ");
    onChange(value);
  }, [onChange, timePart, zonePart]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "100%",
      }}
      spacing={0}
    >
      <Grid item xs={12} md={showTz ? 6 : 12}>
        <TimePicker
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-input": {
              padding: "10px 12px",
            },
            "& .MuiInputLabel-root": {
              top: "-5px",
            },
          }}
          label={label}
          value={timePart}
          disabled={disabled}
          onChange={(time) => setTimePart(time)}
          timeSteps={{ minutes: 15 }}
        />
      </Grid>
      {showTz && (
        <Grid item xs={12} md={6}>
          <Select
            sx={{ width: "100%" }}
            value={zonePart}
            disabled={disabled}
            onChange={(event) => setZonePart(event.target.value)}
          >
            {filterOptions.map((option) => (
              <MenuItem value={option.value}>
                {option.label.substring(0, option.label.indexOf(" "))}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      )}
    </Grid>
  );
}
