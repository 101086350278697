/* eslint-disable react-hooks/rules-of-hooks */
import { Dispatch, useLayoutEffect, useState } from 'react';
import isServer from '../utils/isServer';

function useLocalStorage<T>(
  key: string,
  initialValue: T,
): [T, Dispatch<any>] {
  if (isServer()) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return [initialValue, () => {}];
  }
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (e) {
      console.log(e);
      return initialValue;
    }
  });

  useLayoutEffect(() => {
    try {
      const item = window.localStorage.getItem(key);
      setStoredValue(
        item ? JSON.parse(item) : initialValue,
      );
    } catch (e) {
      console.log(e);
      setStoredValue(initialValue);
    }
  }, [key]);

  useLayoutEffect(() => {
    try {
      const valueToStore =
        storedValue instanceof Function
          ? storedValue(storedValue)
          : storedValue;
      setStoredValue(valueToStore);
      window.localStorage.setItem(
        key,
        JSON.stringify(valueToStore),
      );
    } catch (e) {
      console.log(e);
    }
  }, [setStoredValue, storedValue, key]);

  return [storedValue, setStoredValue];
}

export default useLocalStorage;
